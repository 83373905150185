import React from "react";
import { connect } from "react-redux";
import { setAnswer } from "../redux/actions/answer";
const Choice = ({ combinedAnswer, setAnswer, answer }) => {
    if (!combinedAnswer) {
        return null
    }
    return (
        <>
            <div className="flex justify-center">
                <div className="max-w-lg">
                    {
                        combinedAnswer.map(answerChoice => {
                            return (
                                <button key={answerChoice.random}
                                    onClick={answer === answerChoice.value ? null : setAnswer(answerChoice.value)}
                                    className={`${answer === answerChoice.value ? "bg-white " : "bg-fff0e9 hover:bg-white"} text-slate-800 font-semibold py-4 px-10 rounded-lg mt-10 justify-center w-full drop-shadow-lg transition ease-in delay-250`
                                    }
                                >
                                    {answerChoice.value}
                                </button>
                            )
                        })
                    }
                </div>
            </div>


        </>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        setAnswer: answerText => () => dispatch(setAnswer(answerText))
    };
}
export default connect(
    ({
        quiz: { combinedAnswer },
        quizState: { answer }
    }) =>
        ({ combinedAnswer, answer }),
    mapDispatchToProps
)(Choice);