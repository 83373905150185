import React, { Component } from "react";
import { connect } from "react-redux";

import { submitAnswer } from "../redux/actions/answer";
import { fetchNewQuiz } from "../redux/actions/quiz";

import Question from "./Question";
import Choice from "./Choice";
import QuizState from "./QuizState";

class questionAnswer extends Component {
    sumbitAndFetch = () => {
        this.props.submitAnswer(this.props.correctAnswer);
        this.props.fetchNewQuiz();
    };
    render() {
        const { answer } = this.props;
        return (
            <>
                <QuizState />
                <div className="overflow-auto pb-10">
                    <Question />
                    <Choice />
                    <div className="flex justify-center">
                        <div className="max-w-sm">
                            {answer !== "" ? (
                                <button
                                    onClick={this.sumbitAndFetch}
                                    className="bg-c67353 hover:bg-c88d76 text-white font-bold mb-3 py-2 px-4 rounded-lg mt-10 transition ease-in delay-250"
                                >
                                    Submit Answer
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default connect(
    ({ quizState: { answer }, quiz: { correctAnswer } }) => ({
        answer,
        correctAnswer,
    }),
    { submitAnswer, fetchNewQuiz }
)(questionAnswer);
