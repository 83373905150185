import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faRankingStar,
    faFlagCheckered,
} from "@fortawesome/free-solid-svg-icons";

import { startGame } from "../redux/actions/settings";
import { fetchNewQuiz } from "../redux/actions/quiz";
import { Link } from "react-router-dom";
class StartMenu extends Component {
    startGame = () => {
        this.props.startGame();
        this.props.fetchNewQuiz();
    };
    render() {
        return (
            <div className="flex items-center justify-center h-screen">
                <div>
                    <div>
                        <button
                            onClick={this.startGame}
                            className="w-full bg-fff0e9 hover:bg-white py-5 px-7 rounded-lg font-semibold transition ease-in delay-250 drop-shadow-xl"
                        >
                            <FontAwesomeIcon
                                icon={faFlagCheckered}
                                className="mr-2"
                            />
                            Start Game
                        </button>
                    </div>
                    <div className="mt-10">
                        <Link
                            to="/highscore"
                            className="mt-5 w-full bg-fff0e9 hover:bg-white py-5 px-7 rounded-lg font-semibold transition ease-in delay-250 drop-shadow-xl"
                        >
                            <FontAwesomeIcon
                                icon={faRankingStar}
                                className="mr-2"
                            />
                            Highscore
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, { startGame, fetchNewQuiz })(StartMenu);
