import { SUMBIT_ANSWER, SET_GAME_STARTED, SET_ANSWER, QUIZ } from "../actions/types";

const QUIZ_STATE_DEFAULT = {
    lifeRemaining: 3,
    score: 0,
    answer: ''
}

const quizStateReducer = (state = QUIZ_STATE_DEFAULT, action) => {

    switch (action.type) {
        case SET_GAME_STARTED:
            return QUIZ_STATE_DEFAULT
        case QUIZ.FETCH_SUCCESS:
            return {
                ...state,
                answer: ''
            }
        case SET_ANSWER:
            return {
                ...state,
                answer: action.answer
            }
        case SUMBIT_ANSWER:
            const { answer, lifeRemaining, score } = state;
            const correctAnswer = action.payload
            if (correctAnswer === answer) {
                return {
                    ...state,
                    score: score + 1,
                }
            }
            else {
                return {
                    ...state,
                    lifeRemaining: lifeRemaining - 1,
                }
            }

        default:
            return state;
    }
}

export default quizStateReducer;