import React from "react";
import { connect } from "react-redux";
import uuid from 'uuid/v4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
// Assets
import heartImage from '../assets/heart.svg'

const QuizState = ({ lifeRemaining, score }) => {
    var lifeRemainingList = []
    for (var i = 0; i < lifeRemaining; i++) {
        lifeRemainingList.push({ heartImage, id: uuid() })
    }
    return (
        <div className="text-center font-semibold mt-5 mb-10 text-lg">
            <p>Life Remaining :
                {
                    lifeRemainingList.map(heart => {
                        return (
                            <FontAwesomeIcon icon={faHeart} className="mx-2 text-red-600" />
                        )
                    })
                }</p>
            <p>Score : {score}</p>
        </div>
    )
}
export default connect(
    ({
        quizState: { lifeRemaining, score }
    }) => ({ lifeRemaining, score })
)(QuizState);