import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { store } from './redux/store';
import Wave from './components/Wave';

import App from './components/App';
import Highscore from './components/Highscore';
import Header from "./components/Header";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store}>
    <div className='overflow-y-auto h-[100vh]'>
      <BrowserRouter history={createBrowserHistory()}>
        <Header />
        <Routes>
          <Route path='/' element={<App />} />
          <Route path='/highscore' element={<Highscore />} />
        </Routes>
      </BrowserRouter>
    </div>
    <Wave />
  </Provider>
);