import { QUIZ } from "../actions/types";
import fetchState from "./fetchState";

const DEFAULT_QUIZ = {
    category: '',
    id: '',
    correctAnswer: '',
    incorrectAnswers: [],
    combinedAnswer: [],
    question: '',
    tags: [],
    types: '',
    difficulty: '',
    regions: [],
    isNiche: false,
    fetchState: '',
    message: '',
}
const quizReducer = (state = DEFAULT_QUIZ, action) => {
    switch (action.type) {
        case QUIZ.FETCH_SUCCESS:
            const {
                category,
                id,
                correctAnswer,
                incorrectAnswers,
                question,
                tags,
                types,
                difficulty,
                regions,
                isNiche
            } = action;
            var combinedAnswer = [...incorrectAnswers, correctAnswer];
            combinedAnswer = combinedAnswer.map((item) => ({ value: item, random: Math.random() }));
            combinedAnswer.sort((a, b) => a.random - b.random);
            return {
                ...state,
                category,
                id,
                correctAnswer,
                incorrectAnswers,
                combinedAnswer,
                question,
                tags,
                types,
                difficulty,
                regions,
                isNiche,
                fetchState: fetchState.sucess
            }
        case QUIZ.FETCH_ERROR:
            return {
                ...state,
                fetchState: fetchState.error,
                message: action.message
            }
        default:
            return state;
    }
}

export default quizReducer;