import { SET_GAME_STARTED } from "./types";

export const startGame = () => {
    return {
        type: SET_GAME_STARTED,
        gameStarted: true
    }
}

export const endGame = () => {
    return {
        type: SET_GAME_STARTED,
        gameStarted: false
    }
}