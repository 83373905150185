import { SAVE_USER_NAME } from "../actions/types";

const DEFAULT_USER = {
    username: ''
}

export const userReducer = (state = DEFAULT_USER, action) => {

    switch (action.type) {
        case SAVE_USER_NAME:
            return {
                ...state,
                username: action.username
            }
        default:
            return state;
    }
}

export default userReducer;