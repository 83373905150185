import settingsReducer from "./settings";
import quizReducer from "./quiz";
import quizStateReducer from "./quizState";
import userReducer from "./user";

const rootReducer = {
    settings: settingsReducer,
    quiz: quizReducer,
    quizState: quizStateReducer,
    user: userReducer,
};

export default rootReducer;
