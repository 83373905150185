import React from "react";
import { connect } from "react-redux";

const Question = ({ question }) => {
    return (
        <h3 className="text-lg text-center container mx-auto max-w-lg font-semibold text-slate-900">{question}</h3>
    )
}

export default connect(
    ({
        quiz: { question },
    }) =>
        ({ question }),
    {}
)(Question);