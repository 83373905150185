import React from "react";
import '../index.css';

import { SCORE_STORAGE_KEY } from "../key";
const readStoredScore = () => {
    const scoreMap = JSON.parse(localStorage.getItem(SCORE_STORAGE_KEY));
    return scoreMap ? scoreMap : [];
}
function getRankingClass(ranking) {
    switch (ranking) {
        case 1:
            return 'bg-gradient-to-r from-yellow-300 to-yellow-400';
        case 2:
            return 'bg-gradient-to-r from-gray-400 to-gray-500';
        case 3:
            return 'bg-gradient-to-r from-bronze-light to-bronze';
        default:
            return 'bg-gradient-to-r from-fff0e9 to-ffe6db';
    }
}

function CardRanking({ index, username, score }) {
    console.log(index)
    const cardClasses = [
        'mt-10 flex p-4 rounded-lg shadow-xl ' + getRankingClass(index)
    ];

    return (
        <div className={cardClasses} key={index}>
            <div className="flex flex-col items-center justify-center mr-4">
                <span className="text-4xl font-bold">{index}</span>
                <span className="text-sm font-semibold">Rank</span>
            </div>
            <div className="flex-1">
                <h2 className="text-lg font-bold">{username}</h2>
                <p className="text-sm font-medium text-gray-600">{score} points</p>
            </div>
        </div>
    )
}

const Highscore = () => {
    var storedList = []
    storedList = readStoredScore();
    return (
        <>
            <h1 className="text-center text-4xl font-semibold mt-10">Highscore</h1>
            <div className="flex justify-center mb-10">
                <div className="max-w-xl min-w-[150px] sm:min-w-[500px]">
                    {
                        storedList.map((highscore, index) => {
                            index = index + 1
                            return (
                                <>
                                    <CardRanking key={index} {...highscore} index={index} />
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Highscore;
