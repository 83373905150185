import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Chance from "chance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
// Action
import { endGame } from "../redux/actions/settings";
import { saveUsername } from "../redux/actions/user";

// Image
import quizImage from "../assets/quiz.png";

const Header = ({ gameStarted, lifeRemaining, endGame, saveUsername }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [name, setName] = useState();
    useEffect(() => {
        const chance = new Chance();
        const randomName = chance.name();
        setName(randomName);
        saveUsername(randomName);
    }, [saveUsername]);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = (event) => {
        setIsFocused(false);
        setName(event.target.value);
        saveUsername(name);
    };
    const handleChange = (event) => {
        setName(event.target.value);
    };
    return (
        <header className="sticky top-0 w-full z-20 bg-ffe6db drop-shadow-md">
            <div className="container sm:flex items-center justify-between px-4 py-3 mx-auto">
                <Link
                    to="/"
                    className="flex items-center justify-center sm:justify-start"
                >
                    <img src={quizImage} alt="Logo" className="w-10 mr-2" />
                    <h1 className="text-lg font-bold">MyQuiz</h1>
                </Link>
                <div className="flex items-center justify-center sm:justify-start mt-5 sm:mt-0">
                    {/* <span className="mr-2 hidden md:block">10:00 AM</span> */}
                    {gameStarted && lifeRemaining > 0 ? (
                        <button
                            onClick={endGame}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg transition ease-in delay-250"
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faCircleXmark}
                                    className="mr-2"
                                />
                            </span>
                            End Game
                        </button>
                    ) : (
                        <div className="relative w-64">
                            <input
                                type="text"
                                className={`w-full px-4 py-2 text-gray-900 placeholder-transparent border ${
                                    isFocused
                                        ? "border-blue-500 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        : "border-gray-300"
                                } rounded-md transition-all duration-500 ease-in-out`}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                defaultValue={name}
                            />
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default connect(
    ({ settings: { gameStarted }, quizState: { lifeRemaining } }) => ({
        gameStarted,
        lifeRemaining,
    }),
    { endGame, saveUsername }
)(Header);
