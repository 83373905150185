import React from "react";

const Wave = () => {
    return (
        <div className="ocean">
            <div className="wave"></div>
            <div className="wave"></div>
        </div>
    )
}

export default Wave