import React, { useEffect } from "react";
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import '../index.css';

import QuestionAnswer from "./Quiz";
import StartMenu from "./StartMenu";
import { SCORE_STORAGE_KEY } from "../key";

const storeScore = (scoreMap) => {
  localStorage.setItem(SCORE_STORAGE_KEY,
    JSON.stringify(scoreMap)
  )
}

const readStoredScore = () => {
  const scoreMap = JSON.parse(localStorage.getItem(SCORE_STORAGE_KEY));
  return scoreMap ? scoreMap : [];
}

const App = ({ gameStarted, lifeRemaining, score, username }) => {
  useEffect(() => {
    if (lifeRemaining <= 0) {
      const newRecord = { username, score, id: uuid() };
      const storedList = readStoredScore()
      const mergedList = [...storedList, newRecord];
      mergedList.sort((a, b) => (b.score - a.score));
      storeScore(mergedList.slice(0, 5));
    }
  });
  return (
    <>
      {
        gameStarted && lifeRemaining > 0
          ?
          <QuestionAnswer />
          :
          <StartMenu />
      }
    </>
  )
}

export default connect(
  ({
    settings: { gameStarted },
    quizState: { lifeRemaining, score },
    user: { username }
  }) => ({ gameStarted, lifeRemaining, score, username }),
  {}
)(App);
