import { SET_ANSWER, SUMBIT_ANSWER } from "./types";

export const setAnswer = answerText => {
    return {
        type: SET_ANSWER,
        answer: answerText
    }
}
export const submitAnswer = correctAnswer => {
    return {
        type: SUMBIT_ANSWER,
        payload: correctAnswer
    }
}