import { QUIZ } from "./types";
const API_ADDRESS = 'https://the-trivia-api.com'

export const fetchQuizSuccess = quizJson => {
    const { category, id, correctAnswer, incorrectAnswers, question, tags, difficulty, regions, isNiche } = quizJson[0];
    const types = quizJson[0].type
    return {
        type: QUIZ.FETCH_SUCCESS,
        category,
        id,
        correctAnswer,
        incorrectAnswers,
        question,
        tags,
        types,
        difficulty,
        regions,
        isNiche
    }
}

export const fetchQuizError = error => {
    return {
        type: QUIZ.FETCH_ERROR,
        message: error.message
    }
}
export const fetchNewQuiz = () => dispatch => {
    return fetch(`${API_ADDRESS}/api/questions?limit=1`)
        .then(response => {
            if (response.status !== 200) {
                throw new Error('Unsuccessfully Request to the-trivia-api.com')
            }
            return response.json();
        })
        .then(json => dispatch(fetchQuizSuccess(json)))
        .catch(error => dispatch(fetchQuizError(error)));
}